import React from "react";
import {
  SEO,
  PageLayout,
  OutboundLink,
  InternalLink,
  MarkdownContent,
  SocialMediaV2,
  MultipleComponentIterator,
  BasicCarousel,
  componentIterator,
  Image,
  multipleComponentIterator,
  ReviewV2,
  FishermanIcon,
  CustomerReviewModal,
} from "@bluefin/components";
import { Embed, Button, Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import SecondaryLayout from "../components/SecondaryLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";
import "../style/index.css";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessLocation,
    } = data;

    return (
      <SecondaryLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} subfooter={false} className={"home-page"}>
          <div className={"hero-video-container"}>
            <Embed
              url={"https://player.vimeo.com/video/899306678?background=1"}
              autoplay={true}
              defaultActive={true}
              className={"hero-video"}
            />
          </div>
          <div className={"contact-form-section"}>
            <Button
              primary={true}
              size={"large"}
              to={"https://trumedspa.myaestheticrecord.com/online-booking"}
              as={OutboundLink}
            >
              Book Now
            </Button>
            <Button
              primary={true}
              size={"large"}
              to={"tel:+18188048959"}
              as={OutboundLink}
            >
              Call to Book
            </Button>
            <Button
              primary={true}
              size={"large"}
              to={"/services/"}
              as={InternalLink}
            >
              View Services
            </Button>
          </div>
          <div className={"about-section"}>
            <Grid stackable={true} doubling={true} columns={2}>
              <Grid.Column width={8} className={"markdown-column"}>
                <MarkdownContent
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "about_section_description",
                  })}
                />
              </Grid.Column>
              <Grid.Column width={8} className={"image-column"}>
                <Embed
                  url={"https://player.vimeo.com/video/899313917?background=1"}
                  autoplay={true}
                  defaultActive={true}
                />
              </Grid.Column>
            </Grid>
          </div>
          <div className={"image-gallery-section"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "image_section_header",
                defaultValue: "WE'RE SOCIAL",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "image_section_description",
              })}
            />
            <SocialMediaV2>
              <MultipleComponentIterator
                iterator={fishermanBusiness.socialMedia}
                components={[
                  {
                    component: <SocialMediaV2.Platform />,
                    propMap: { key: "_id", url: "link", platform: "type" },
                    children: [
                      {
                        component: <SocialMediaV2.Icon />,
                        propMap: { icon: "type" },
                      },
                      {
                        component: <SocialMediaV2.Handle />,
                        propMap: { link: "link" },
                      },
                    ],
                  },
                ]}
              />
            </SocialMediaV2>
            <BasicCarousel
              className={"image-gallery"}
              size={4}
              grouping={2}
              displayIndicators={true}
              displayPlayPauseButton={true}
              autoScroll={true}
              tablet={{}}
              mobile={{}}
              animation={{
                name: "blur",
                duration: 200,
                transitionProps: {
                  preAnimating: { opacity: 0.99, blur: "2px" },
                  animating: { opacity: 0.99, blur: "2px" },
                },
              }}
              items={componentIterator({
                iterator: getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "ImageCollection",
                  componentIdentifier: "image_gallery",
                }),
                component: (
                  <Image background={true} className={"gallery-image-item"} />
                ),
                propMap: { src: "__all__" },
              })}
            />
          </div>
          <div
            className={"elfsight-app-49969dd7-134d-43d0-8882-9aee356f1f93"}
            data-elfsight-app-lazy={true}
          />
          <div className={"reviews-section"}>
            <BasicCarousel
              size={3}
              displayIndicators={false}
              stepSize={1}
              autoScroll={false}
              displayPlayPauseButton={false}
              animation={{ name: "blur", duration: 160 }}
              items={multipleComponentIterator({
                iterator: fishermanBusiness.reviews,
                components: [
                  {
                    component: <ReviewV2 />,
                    propMap: { key: "_id" },
                    children: [
                      { component: <FishermanIcon iconName={"quote-left"} /> },
                      {
                        component: <ReviewV2.Text charLimit={360} />,
                        propMap: { text: "text" },
                      },
                      {
                        component: <ReviewV2.Line />,
                        children: [
                          { component: <ReviewV2.Rating rating={5} /> },
                          {
                            component: <ReviewV2.RatingLabel />,
                            propMap: { rating: "rating" },
                          },
                        ],
                      },
                      {
                        component: <ReviewV2.Line />,
                        children: [
                          {
                            component: <ReviewV2.Author />,
                            propMap: { author: "author" },
                          },
                          {
                            component: <ReviewV2.Source />,
                            propMap: { source: "source", link: "link" },
                          },
                        ],
                      },
                    ],
                  },
                ],
              })}
            />
            <div className={"submit-review-button-container"}>
              <CustomerReviewModal
                businessId={fishermanBusiness._id}
                locations={allFishermanBusinessLocation.nodes}
                businessType={fishermanBusiness.type}
              />
            </div>
          </div>
        </PageLayout>
      </SecondaryLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      socialMedia {
        _id
        link
        type
      }
      reviews {
        _id
        author
        text
        source
        rating
        link
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        street
        city
        name
        phoneNumber
        primaryLocation
      }
    }
  }
`;
